<template>
    <div class="echartLayout">
        <div id="sxy"></div>
    </div>
</template>

<script>
    import G6 from '@antv/g6';
    export default {
        name: "sxy",
        props:{
            dataVo:{
                type:Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                graph:{},
                dataURL:'',
                data: {
                    id:'0',
                    company:'哈尔滨话梅一封什么设么公司是和华东师范',
                    children:[
                        {
                            flag:'up',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'up',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'up',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'up',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'up',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'down',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'down',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'down',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'down',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },
                        {
                            flag:'down',
                            "money": "7614232.373万人民币",
                            company:'上海小度人工智能有限公司'
                        },

                    ],
                },
            }
        },
        watch:{
            dataVo:function (val) {
                console.log(val)
                this.data = val
                this.graph.data(val); // 读取 Step 2 中的数据源到图上
                this.graph.changeData(val);
                this.graph.fitView();
            }

        },
        mounted() {
            if (typeof window !== 'undefined')
                window.onresize = () => {
                    if (!this.graph || this.graph.get('destroyed')) return;
                    if (!container || !container.scrollWidth || !container.scrollHeight) return;
                    this.graph.changeSize(container.scrollWidth, container.scrollHeight - 30);
                };
            this.initEchart()
        },
        methods: {
             deliverWord  (str, maxWidth, fontSize){
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    console.log(currentWidth,maxWidth)
                    if (currentWidth > maxWidth) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth) {
                        res = `${str.substr(0, i)}\n${str.substr(i)}`;
                    }
                });
                console.log(res)
                return res;
            },
             fittingString  (str, maxWidth, fontSize) {
                const ellipsis = '...';
                const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    if (currentWidth > maxWidth - ellipsisLength) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth - ellipsisLength) {
                        res = `${str.substr(0, i)}${ellipsis}`;
                    }
                });
                return res;
            },
            initEchart() {
                var that = this
                G6.registerNode('card-noded', {
                    getAnchorPoints() {
                        return [
                            [1,0.5], // 左侧中间
                            [0,0.5]
                        ];
                    },
                    draw: function drawShape(cfg, group) {
                        console.log(cfg,'cfgcfgcfgcfg111111=====')
                        const r = 2;
                        const color = '#CCCCCC';
                        const w = cfg.size[0];
                        const h = cfg.size[1];
                        var lw = 0
                        const shape = group.addShape('rect', {
                            attrs: {
                                x: -w / 2,
                                y: -h / 2,
                                width:cfg.children ? 380 : 500, //200,
                                height:cfg.children ?  h :160, // 60
                                stroke:cfg.children ? '#457DFE' : color,
                                radius: 5,
                                fill: cfg.children ? '#457DFE' :'rgba(255,255,255,.3)',
                            },
                            name: 'main-box',
                            draggable: false,
                        });

                        // title text
                        !cfg.children && group.addShape('rect', {
                            attrs: {
                                x: -w/2,
                                y: -h/2,
                                r:[5,5,0,0  ],
                                width: 500, //200,
                                height: 80, // 60
                                fill: 'rgba(69, 125, 254, .4)',
                                stroke:'rgba(69, 125, 254, .4)',

                            },
                            name: 'target',
                        });
                        group.addShape('text', {
                            attrs: {
                                x: -w/2 + 15,
                                y: !cfg.children ?cfg.company.length > 18 ? 50 : 0 : 30,
                                fontSize:28,
                                lineHeight: 32,
                                text:!cfg.children ? cfg.company.length > 17 ? cfg.company.slice(0, 17) + '\n'+ '\n' + cfg.company.slice( -(cfg.company.length - 17)) : cfg.company  : cfg.company.length > 12 ? cfg.company.slice(0, 12) + '\n' + cfg.company.slice( -(cfg.company.length - 12)) : cfg.company ,
                                fill:cfg.children ? '#fff' : '#666',
                            },
                            name: 'target',
                        });

                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +15,
                                y:60,
                                fontSize:28,
                                lineHeight: 24,
                                text: `交易金额: ${cfg.money}`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });



                        return shape;
                    },
                    setState(name, value, item) {
                        console.log(name, value, item);
                        if (name === 'collapsed') {
                            const marker = item.get('group').find((ele) => ele.get('name') === 'collapse-icon');
                            const icon = value ? G6.Marker.expand : G6.Marker.collapse;
                            marker.attr('symbol', icon);
                        }
                    },
                });

                const container = document.getElementById('sxy');
                const width = container.scrollWidth;
                const height = container.scrollHeight || 500;
                this.graph = new G6.TreeGraph({
                    container: 'sxy',
                    renderer:'svg',
                    width,
                    height,
                    modes: {
                        default: ['drag-canvas'],
                    },
                    defaultNode: {
                        type: 'card-noded',
                        size: [200, 110],
                    },
                    defaultEdge: {
                        type: 'polyline',
                        style: {
                            radius: 5,
                            offset:120,
                            endArrow: true,
                            lineWidth: 1,
                            stroke: '#ccc',
                        },
                    },
                    layout: {
                        type: 'dendrogram',
                        direction: 'H',
                        nodeSep:270,
                        rankSep:790,
                        getSide: (d) => {
                            console.log(d)
                            if (d.data.flag === 'up' ) return 'left';
                            return 'right';
                        },
                    },
                });
                this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                this.graph.render(); // 渲染图
                this.graph.fitView();
                this.dataURL = this.graph.toDataURL();
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 900px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        padding: 5px 10px;
        background: #42b983;
    }
</style>
