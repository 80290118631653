<template>
    <div class="echartLayout">
        <div id="gqct"></div>
    </div>
</template>

<script>
    import G6 from '@antv/g6';
    export default {
        name: "gqct",
        props:{
            dataVo:{
                type:Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                graph:{},
                data: {
                    nodes:[
                        {
                            "tagList": [
                                {
                                    "label": "大股东",
                                    "type": 1
                                },
                                {
                                    "label": "最终受益人",
                                    "type": 2
                                },
                                {
                                    "label": "啥呀",
                                    "type": 3
                                }
                            ],
                            "amount": "16710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            "id": '0',
                            actualHolding:true,
                            "percent": "95%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,

                            "id": '2',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,

                            "id": '3',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,

                            "id": '4',
                            "percent": "0.5%",
                        },
                        {
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            actualHolding:false,
                            isMiddle:true,
                            "id": '5',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,
                            "id": '6',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊",
                            actualHolding:false,
                            "id": '7',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,
                            "id": '8',
                            "percent": "0.5%",
                        },
                        {
                            "tagList": [],
                            "amount": "6710.64万人民币",
                            "parentName": "北京百度网讯科技有限公司",
                            "name": "崔珊珊",
                            actualHolding:false,
                            "id": '9',
                            "percent": "0.5%",
                        },

                    ],
                    edges:[
                        {
                            source: '0',
                            target: '5',
                        },
                        {
                            source: '2',
                            target: '5',
                        },
                        {
                            source: '3',
                            target: '5',
                        },
                        {
                            source: '4',
                            target: '5',
                        },
                        {
                            source: '5',
                            target: '6',
                        },
                        {
                            source: '5',
                            target: '7',
                        },
                        {
                            source: '5',
                            target: '8',
                        },
                        {
                            source: '5',
                            target: '9',
                        },
                        {
                            source: '5',
                            target: '10',
                        },
                    ],
                }
            }
        },
        watch:{
            dataVo:function (val) {
                console.log(val,'股权穿透')
                this.data = val
                this.initEchart()

                // this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                // this.graph.changeData(this.data);
                // this.graph.fitView();
            }

        },
        mounted() {
        },
        methods: {
             fittingString  (str, maxWidth, fontSize) {
                const ellipsis = '...';
                const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    if (currentWidth > maxWidth - ellipsisLength) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth - ellipsisLength) {
                        res = `${str.substr(0, i)}${ellipsis}`;
                    }
                });
                return res;
            },
            initEchart() {
                G6.registerEdge(
                    'mid-point-edge',
                    {
                        afterDraw(cfg, group) {
                            // 获取图形组中的第一个图形，在这里就是边的路径图形
                            const shape = group.get('children')[0];
                            // 获取路径图形的中点坐标
                            const midPoint = shape.getPoint(0.5);
                            // 在中点增加一个矩形，注意矩形的原点在其左上角
                            group.addShape('rect', {
                                attrs: {
                                    width: 10,
                                    height: 10,
                                    fill: '#f00',
                                    // x 和 y 分别减去 width / 2 与 height / 2，使矩形中心在 midPoint 上
                                    x: midPoint.x - 5,
                                    y: midPoint.y - 5,
                                },
                            });
                        },
                        update: undefined,
                    },
                    'cubic',
                );
                G6.registerNode(
                    'sql',
                    {
                        getAnchorPoints() {
                            return [
                                [0.5, 0], [0.5, 1]
                            ];
                        },
                        drawShape(cfg, group) {
                            const rect = group.addShape('rect', {
                                attrs: {
                                    x: -75,
                                    y: -25,
                                    width: cfg.isMiddle? 450: 300,
                                    height: 100,
                                    radius:cfg.actualHolding ? [0,0,5,5]:5 ,
                                    stroke:cfg.actualHolding ? '#e9746a': '#83dbf9',
                                    fill:cfg.isMiddle ? '#3884f7': cfg.id === '0' ? '#fefbfb' :'#f6fafe'
                                },
                                name: 'rect-shape',
                            });
                            cfg.actualHolding && group.addShape('rect', {
                                attrs: {
                                    x:-75,
                                    y:-65,
                                    width:300,
                                    height:40,
                                    radius: [5,5,0,0],
                                    stroke:'#e9746a',
                                    fill:'#e9746a'
                                },
                                name: 'rect-shape',
                            });
                            cfg.actualHolding && group.addShape('text', {
                                attrs: {
                                    x:70,
                                    y:-35,
                                    text: '疑似实际控股人',
                                    fill: '#fff',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign:'center'
                                },
                                name: 'text-shape',
                            });
                            !cfg.isMiddle && group.addShape('text', {
                                attrs: {
                                    x:110,
                                    y: cfg.position === 'up' ? 120: -35,
                                    text: `${cfg.percent}`,
                                    fill: '#3884f7',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign:'center'
                                },
                                name: 'text-shape',
                            });
                                group.addShape('text', {
                                    attrs: {
                                        x:70,
                                        y:10,
                                        text: !cfg.isMiddle ? cfg.name :'',
                                        fill: '#333',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        textAlign:'center'
                                    },
                                    name: 'text-shape',
                                });
                            !cfg.isMiddle && group.addShape('text', {
                                attrs: {
                                    x:70,
                                    y:60,
                                    text:`认缴金额: ${cfg.amount}`,
                                    fill: '#474747',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign:'center'
                                },
                                name: 'text-shape',
                            });
                            cfg.isMiddle && group.addShape('text', {
                                attrs: {
                                    x:-20,
                                    y:45,
                                    width:400,
                                    height:100,
                                    text:`${cfg.parentName}`,
                                    fill: '#fff',
                                    fontSize: 28,
                                    fontWeight: 'bold',
                                },
                                name: 'text-shape',
                            });
                            return rect;
                        },
                    },
                    'single-node',
                );

                const container = document.getElementById('gqct');
                const width = container.scrollWidth;
                const height = container.scrollHeight || 500;
                 this.graph = new G6.Graph({
                    container: 'gqct',
                    renderer:'svg',
                    width,
                    height,
                    fitView: true,
                    fitViewPadding: 30,
                    modes: {
                        default: [
                            'drag-combo',
                            'drag-node',
                            'drag-canvas',
                            {
                                type: 'collapse-expand-combo',
                                relayout: false,
                            },
                        ],
                    },
                    layout: {
                        type: 'dagre',
                        ranksep: 40,
                        nodesep: 50,
                    },
                    defaultNode: {
                        size: [300, 150],
                        type: 'sql',
                    },

                    defaultEdge: {
                        type: 'polyline',
                        style: {
                            radius: 10,
                            offset:70,
                            endArrow: true,
                            lineWidth: 1,
                            stroke: '#ccc',
                        },
                        anchorPoints: [[0.5, 0], [0.5, 1]]
                    },
                    defaultCombo: {
                        type: 'rect',
                        style: {
                            fillOpacity: 0.1,
                        },
                    },
                    nodeStateStyles: {
                        selected: {
                            stroke: '#d9d9d9',
                            fill: '#5394ef',
                        },
                    },
                });
                var that = this
                this.data.nodes.forEach(function (node) {
                    if (node.parentName){
                        node.parentName = that.fittingString(node.parentName, 400, 28);
                    }
                });
                // this.data.edges.forEach(function (edge) {
                //     edge.label = this.fittingString(edge.parentName, 32, 32);
                // });
                this.graph.data(this.data);
                this.graph.render();
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 900px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        padding: 5px 10px;
        background: #42b983;
    }
</style>
