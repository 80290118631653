import Axios from '../axios'
import BaseUrl from '../base'
//18.1.产品分析-放款额度
// http://192.168.0.27:8051/jydata_jinxiang/upDownStream?ENTERPRISE_ID=464aa1b28e1c4d89aacf3e4c426b950d
export function upDownStream(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/jydata_jinxiang/upDownStream/',
        params: data
    })
}
//http://192.168.0.27:8051/eyeInterface/interface453?KEYWORDS=小米科技有限责任公司
export function interface453(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/eyeInterface/interface453/',
        params: data
    })
}

//http://192.168.0.27:8051/eyeInterface/interface455?KEYWORDS=小米科技有限责任公司  股权穿透
export function interface455(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/eyeInterface/interface455/',
        params: data
    })
}
//http://192.168.0.27:8051/eyeInterface/interface455?KEYWORDS=小米科技有限责任公司  企业对外投资
export function interface823(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/eyeInterface/interface823/',
        params: data
    })
}
//http://192.168.0.27:8051/eyeInterface/interface1033?KEYWORDS=小米科技有限责任公司  人员控股企业
export function interface1033(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/eyeInterface/interface1033/',
        params: data
    })
}

//http://192.168.0.27:8051/eyeInterface/interface783?KEYWORDS=小米科技有限责任公司  企业图谱
export function interface783(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/eyeInterface/interface783/',
        params: data
    })
}
