<template>
    <div class="echartLayout">
        <div id="mountNode"></div>
    </div>
</template>

<script>
    import G6 from '@antv/g6';
    export default {
        name: "personRelation",
        props:{
            dataVo:{
                type:Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                graph:{},
                data: {
                    "tagList": [],
                            company: "北京百度网讯科技有限公司",
                             cid: 0,
                            children: [
                                {
                                "tagList": [
                                    {
                                        "name": "大股东",
                                        "type": 1
                                    },
                                    {
                                        "name": "最终受益人",
                                        "type": 2
                                    }
                                ],
                                actualHolding:true,
                                "amount": "1335417.36万人民币",
                                "parentName": "北京百度网讯科技有限公司",
                                "name": "李彦宏",
                                    "percent": "99%",
                                "id": '1984012283',

                            },
                                {
                                    "tagList": [
                                        {
                                            "name": "大股东",
                                            "type": 1
                                        },
                                        {
                                            "name": "最终受益人",
                                            "type": 2
                                        },
                                        {
                                            "name": "啥呀",
                                            "type": 3
                                        }
                                    ],
                                    "amount": "6710.64万人民币",
                                    "parentName": "北京百度网讯科技有限公司",
                                    "name": "崔珊珊",
                                    "id": "1905070872",
                                    actualHolding:false,
                                    "percent": "0.5%",
                                },
                                {
                                    "tagList": [],
                                    "amount": "6710.64万人民币",
                                    "parentName": "北京百度网讯科技有限公司",
                                    "name": "崔珊珊",
                                    actualHolding:false,

                                    "id": "1905070873",
                                    "percent": "0.5%",
                                },
                                {
                                    "tagList": [],
                                    "amount": "6710.64万人民币",
                                    "parentName": "北京百度网讯科技有限公司",
                                    "name": "崔珊珊",
                                    actualHolding:false,

                                    "id": "1905070874",
                                    "percent": "0.5%",
                                },
                                {
                                    "tagList": [],
                                    "amount": "6710.64万人民币",
                                    "parentName": "北京百度网讯科技有限公司",
                                    "name": "崔珊珊",
                                    actualHolding:false,

                                    "id": "1905070875",
                                    "percent": "0.5%",
                                }],
                }
            }
        },
        watch:{
            dataVo:function (val) {
                console.log(val)
                this.data = val
                // this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                this.graph.changeData(this.data);
                this.graph.fitView();

            }

        },
        mounted() {


            this.initEchart()
            // this.g6()


            // this.graph.downloadFullImage('mountNode','image/png', {
            //     padding: [30, 15, 15, 15],
            // });
        },
        methods: {
            initEchart() {
                G6.registerNode('card-node', {
                    getAnchorPoints() {
                        return [
                            [0,1], // 左侧中间
                            [0, 0.5], // 右侧中间
                        ];
                    },
                    draw: function drawShape(cfg, group) {
                        const r = 2;
                        const color = '#CCCCCC';
                        const w = cfg.size[0];
                        const h = cfg.size[1];
                        var lw = 0
                        const shape = group.addShape('rect', {
                            attrs: {
                                x: -w / 2,
                                y: -h / 2,
                                width: cfg.cid === 0 ?  910 : 890, //200,
                                height: cfg.tagList.length === 0 ? 88 : h, // 60
                                stroke: color,
                                radius: r,
                                fill: 'rgba(255,255,255,.3)',
                            },
                            name: 'main-box',
                            draggable: false,
                        });

                        group.addShape('rect', {
                            attrs: {
                                x: -w/2,
                                y: -h/2,
                                width: 8, //200,
                                height: cfg.tagList.length === 0 ? 88 : h, // 60
                                fill: cfg.cid === 0 ?  '#457DFE' :cfg.actualHolding ? '#E67B6D' :'#70AA7B',
                                radius: [r, r, 0, 0],
                            },
                            name: 'title-box',
                            draggable: false,
                        });
                        // title text
                        if (cfg.company != null){
                            group.addShape('text', {
                                attrs: {
                                    textBaseline: 'top',
                                    x:-w/2+59,
                                    y: -10,
                                    lineHeight: 100,
                                    text: cfg.company,
                                    fontSize:18,
                                    fontWeight:500,
                                    fill: '#333',
                                },
                                name: 'name',
                            });

                        }
                        cfg.children && group.addShape('marker', {
                            attrs: {
                                x: -w / 2 +30,
                                y: 0,
                                r: 10,
                                cursor: 'pointer',
                                symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
                                stroke: '#cccccc',
                                lineWidth: 1,
                                fill: '#fff',
                            },
                            name: 'collapse-icon',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 + 30,
                                y: -30,
                                fontSize:16,
                                lineHeight: 24,
                                text: cfg.name,
                                fill: '#333333',
                            },
                            name: 'target',
                        });

                        cfg.tagList.map((item,index)=>{
                            if (index == 0){
                                lw = 0
                            }else{
                                lw +=  cfg.tagList[index-1].name.length*24+20*index
                            }
                        console.log(lw)
                            group.addShape('dom', {
                                attrs: {
                                    r:20,
                                    x:-w / 2+lw +30,
                                    y:-15,
                                    labelPosition:'left',
                                    width: item.name.length*24,
                                    height: 26,
                                    html: `
    <div class="main">
      <div class="item" style="text-align: center;padding:2px 5px;background: ${item.type ===1 ? 'rgba(230, 123, 109, .5)' : item.type ===2 ? 'rgba(69, 125, 254, .5)' : 'rgba(255, 174, 0, .5)'};
      color: ${item.type ===1 ? '#FF6259' : item.type ===2 ? '#457DFE' : '#C88800'}
      ">${item.name}</div>
    </div>
      `,
                                },
                                name: 'dom-shape',
                                draggable: false,
                            });

                        })
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 + 30,
                                y:cfg.tagList.length ===0 ? 18 : 38,
                                fontSize:16,
                                lineHeight: 24,
                                text: `股比:`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +70,
                                y: cfg.tagList.length ===0 ? 18 : 38,
                                fontSize:16,
                                lineHeight: 24,
                                text: `${cfg.percent}`,
                                fill: '#FE8300',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +254,
                                y: cfg.tagList.length ===0 ? 18 : 38,
                                fontSize:16,
                                lineHeight: 24,
                                text: '认缴金额:',
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +323,
                                y: cfg.tagList.length ===0 ? 18 : 38,
                                fontSize:16,
                                lineHeight: 24,
                                text: `${cfg.amount}`,
                                fill: '#FE8300',
                            },
                            name: 'target',
                        });
                        return shape;
                    },
                    setState(name, value, item) {
                        console.log(name, value, item);
                        if (name === 'collapsed') {
                            const marker = item.get('group').find((ele) => ele.get('name') === 'collapse-icon');
                            const icon = value ? G6.Marker.expand : G6.Marker.collapse;
                            marker.attr('symbol', icon);
                        }
                    },
                });
                // G6.registerNode('dom', {
                //     getAnchorPoints() {
                //         return [
                //             [0,1], // 左侧中间
                //             [0, 0.5], // 右侧中间
                //         ];
                //     },
                //     draw: function drawShape(cfg, group) {
                //         const r = 2;
                //         const color = '#CCCCCC';
                //         const w = cfg.size[0];
                //         const h = cfg.size[1];
                //         const shape = group.addShape('rect', {
                //             attrs: {
                //                 x: -w / 2,
                //                 y: -h / 2,
                //                 width: cfg.cid === 0 ?  910 : 890, //200,
                //                 height: h, // 60
                //                 stroke: color,
                //                 radius: r,
                //                 fill: 'rgba(255,255,255,.3)',
                //             },
                //             name: 'main-box',
                //             draggable: true,
                //         });
                //
                //         group.addShape('rect', {
                //             attrs: {
                //                 x: -w/2,
                //                 y: -h/2,
                //                 width: 8, //200,
                //                 height: h, // 60
                //                 fill: cfg.cid === 0 ?  '#457DFE' :cfg.actualHolding ? '#E67B6D' :'#70AA7B',
                //                 radius: [r, r, 0, 0],
                //             },
                //             name: 'title-box',
                //             draggable: true,
                //         });
                //         group.addShape('text', {
                //             attrs: {
                //                 textBaseline: 'top',
                //                 x: -w / 2 + 8,
                //                 y: -h / 2 + 2,
                //                 lineHeight: 20,
                //                 text: cfg.id,
                //                 fill: '#fff',
                //             },
                //             name: 'title',
                //         });
                //         // title text
                //         group.addShape('text', {
                //             attrs: {
                //                 textBaseline: 'top',
                //                 x:-w/2+59,
                //                 y: -10,
                //                 lineHeight: 100,
                //                 text: cfg.label,
                //                 fontSize:18,
                //                 fill: '#333',
                //             },
                //             name: 'name',
                //         });
                //         cfg.children && group.addShape('marker', {
                //             attrs: {
                //                 x: -w / 2 +30,
                //                 y: 0,
                //                 r: 10,
                //                 cursor: 'pointer',
                //                 symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
                //                 stroke: '#cccccc',
                //                 lineWidth: 1,
                //                 fill: '#fff',
                //             },
                //             name: 'collapse-icon',
                //         });
                //         cfg.tagList.map((item,index)=>{
                //             console.log('xunhuan',item.label.length)
                //             group.addShape('rect', {
                //                 attrs: {
                //                     x: -w/2+item.label.length*24+(index+1)*24,
                //                     y: -h/2+53,
                //                     width: item.label.length*12+(index+1)*24, //200,
                //                     height: 24, // 60
                //                     lineHeight: 24,
                //
                //                     labelCfg: {
                //                         style: {
                //                             fill: '#9254de',
                //                             color:'#333',
                //                             fontSize: 100,
                //                         },
                //                         position: 'top',
                //                     },
                //                 },
                //                 name: 'target',
                //             });
                //             // group.addShape('text', {
                //             //     attrs: {
                //             //         textBaseline: 'center',
                //             //         x: -w/2+item.name.length*12+index*12,
                //             //         y: -h/3,
                //             //         lineHeight: 24,
                //             //         text: item.name,
                //             //         fill: item.type == 1 ? '#FF6259' : item.type === 2 ? '#457DFE' : item.type === 3 ? '#FFAE00' : '#01B21E',
                //             //     },
                //             //     name: 'target',
                //             // });
                //         })
                //         return shape;
                //     },
                //     setState(name, value, item) {
                //         console.log(name, value, item);
                //         if (name === 'collapsed') {
                //             const marker = item.get('group').find((ele) => ele.get('name') === 'collapse-icon');
                //             const icon = value ? G6.Marker.expand : G6.Marker.collapse;
                //             marker.attr('symbol', icon);
                //         }
                //     },
                // });
                const container = document.getElementById('mountNode');
                const width = container.scrollWidth;
                const height = container.scrollHeight || 500;
                this.graph = new G6.TreeGraph({
                    container: 'mountNode',
                    renderer:'svg',
                    width,
                    height,
                    modes: {
                        default: ['drag-canvas'],
                    },
                    defaultNode: {
                        type: 'card-node',
                        size: [910, 110],
                    },
                    defaultEdge: {
                        labelCfg: {
                            autoRotate: false,
                        },
                        type: 'cubic-horizontal',
                        style: {
                            endArrow:false,
                            stroke: '#cccccc',
                        },
                    },
                    layout: {
                        align:'LR',
                        type: 'indented',
                        direction: 'LR',
                        dropCap: true,
                        indent: 20,
                        getHeight: () => {
                            return 80;
                        },
                    },
                });
                this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                this.graph.render(); // 渲染图
                this.graph.fitView();
            },
        }
    }
</script>

<style scoped>
    .echartLayout {

    }
    .main{
        width: 900px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        padding: 5px 10px;
        background: #42b983;
    }
</style>
