<template>
    <div class="echartLayout">
        <div id="dwtz"></div>
    </div>
</template>

<script>
    import G6 from '@antv/g6';
    export default {
        name: "dwtz",
        props:{
            dataVo:{
                type:Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                graph:{},
                data: {
                    id:'0',
                    name:'哈尔滨话梅一封什么设么公司是和华东师范',
                    children:[
                        {
                            "regStatus": "存续",
                            "amount": '76141.37',
                            "estiblishTime": '1603209600000',
                            "regCapital": "7614232.373万人民币",
                            "amountSuffix": "万元",
                            "percent": "100%",
                            "legalPersonName": "景鲲",
                            "business_scope": "一般项目：从事智能科技领域内的技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；硬件设计；语音技术平台服务；人工智能公共数据平台；人工智能基础资源与技术平台；人工智能公共服务平台技术咨询服务；人工智能硬件销售；家用视听设备销售；计算机软硬件及辅助设备批发；计算机软硬件及辅助设备零售；软件销售；互联网销售（除销售需要许可的商品）；信息技术咨询服务；信息系统集成服务；信息系统运行维护服务；人工智能行业应用系统集成服务；广告制作、设计、代理；广告发布（非广播电台、电视台、报刊出版单位）；第一类医疗器械销售；第二类医疗器械销售。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：网络文化经营；第二类增值电信业务；第三类医疗器械经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）",
                            "orgType": "有限责任公司（自然人投资或控股的法人独资）",
                            "creditCode": "91310115MA1K4MA10K",
                            "name": "上海小度人工智能有限公司",
                            "alias": "小度",
                            "id": '3485130445',
                            "category": "科技推广和应用服务业",
                            "personType": '1',
                            "base": "sh"
                        },
                        {
                            "regStatus": "存续",
                            "amount": '3000',
                            "estiblishTime": '1583942400000',
                            "regCapital": "3000万人民币",
                            "amountSuffix": "万元",
                            "percent": "100%",
                            "legalPersonName": "李宁",
                            "business_scope": "技术开发、技术转让、技术咨询、技术服务、技术推广；软件开发；经济贸易咨询；设计、制作、代理、发布广告；货物进出口、技术进出口、代理进出口；销售化妆品、卫生用品、消毒用品、体育用品、针、纺织品、服装、鞋帽、日用品、家具、首饰、自行开发后的产品、电子产品、机械设备、计算机、软件及辅助设备、医疗器械I、II类；计算机系统服务；健康管理（须经审批的诊疗活动除外）；健康咨询（须经审批的诊疗活动除外）；企业管理咨询；会议服务；承办展览展示活动；心理咨询（不含诊疗活动）；贸易代理；企业策划、设计；从事互联网文化活动；出版物批发；出版物零售；销售食品；经营电信业务；零售药品；销售第三类医疗器械。（企业依法自主选择经营项目，开展经营活动；从事互联网文化活动、出版物批发、出版物零售、销售食品、经营电信业务、零售药品、销售第三类医疗器械以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）",
                            "orgType": "有限责任公司(法人独资)",
                            "creditCode": "91110108MA01QAH21L",
                            "name": "百度健康（北京）科技有限公司丰富的思考建安费;阿士大夫和家收到货接客服",
                            "alias": "百度健康",
                            "id": '3411760479',
                            "category": "科技推广和应用服务业",
                            "personType": 1,
                            "base": "bj"
                        },
                        {
                            "regStatus": "存续",
                            "amount": '1000',
                            "estiblishTime": '1543939200000',
                            "regCapital": "1000万人民币",
                            "amountSuffix": "万人民币",
                            "percent": "100%",
                            "legalPersonName": "黄艳",
                            "business_scope": "计算机软硬件、电子产品的技术开发、技术推广、技术转让、技术咨询、技术服务；销售自行开发后的产品、机械设备；计算机系统服务；基础软件服务；应用软件服务；数据处理（数据处理中的银行卡中心、PUE值在1.4以上的云计算数据中心除外）；软件开发；软件咨询；产品设计；模型设计；经济贸易咨询；销售医疗器械Ⅰ、Ⅱ类、计算机、软件及辅助设备；健康管理、健康咨询（须经审批的诊疗活动除外）；经营电信业务；销售第三类医疗器械；生产第二类、第三类医疗器械。（市场主体依法自主选择经营项目，开展经营活动；经营电信业务、生产第二类、第三类医疗器械、销售第三类医疗器械以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）",
                            "orgType": "有限责任公司(法人独资)",
                            "creditCode": "91110108MA01FYN333",
                            "name": "北京康夫子健康技术有限公司",
                            "alias": "康夫子",
                            "id": '3277354448',
                            "category": "科技推广和应用服务业",
                            "personType": 1,
                            "base": "bj"
                        },
                        {
                            "regStatus": "存续",
                            "amount": 1187.93,
                            "estiblishTime": 1433174400000,
                            "regCapital": "1187.931万人民币",
                            "amountSuffix": "万人民币",
                            "percent": "100%",
                            "legalPersonName": "黄小勇",
                            "business_scope": "信息技术咨询服务；软件开发；软件技术转让；软件技术服务；利用信息网络经营游戏产品；电子商务平台的开发建设；信息系统集成服务；信息电子技术服务；科技信息咨询服务；电子产品销售；移动互联网研发和维护；第二类增值电信业务中的呼叫中心业务、信息服务业务（仅限互联网信息服务）。（未经批准不得从事P2P网贷、股权众筹、互联网保险、资管及跨界从事金融、第三方支付、虚拟货币交易、ICO、非法外汇等互联网金融服务）",
                            "orgType": "有限责任公司（自然人投资或控股的法人独资）",
                            "creditCode": "91430111338411878E",
                            "name": "湖南微算互联信息技术有限公司",
                            "alias": "微算互联",
                            "id": '3147066859',
                            "category": "软件和信息技术服务业",
                            "personType": 1,
                            "base": "hun"
                        },
                        {
                            "regStatus": "存续",
                            "amount": '10000',
                            "estiblishTime": '1512057600000',
                            "regCapital": "10000万人民币",
                            "amountSuffix": "万人民币",
                            "percent": "100%",
                            "legalPersonName": "陈尚义",
                            "business_scope": "人工智能、大数据、云计算、物联网和区块链等技术开发和应用；无人车的技术开发及营运；人机交互平台研发及服务；与智能城市相关的交通、出行、家居、环保技术研发及服务；与智能城市相关的教育、医疗、安防技术研发；计算机软、硬件的设计、技术开发、销售；数据库及计算机互联网络信息服务，系统集成服务；设计、制作、代理、发布广告；经济信息咨询；数据内容服务，数据处理和存储服务；以上技术及相关业务的咨询服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动）",
                            "orgType": "有限责任公司（自然人投资或控股的法人独资）",
                            "creditCode": "91130629MA09DHY337",
                            "name": "百度雄安科技有限公司",
                            "alias": "百度雄安",
                            "id": '3125216335',
                            "category": "软件和信息技术服务业",
                            "personType": 1,
                            "base": "heb"
                        },

                    ]
                },
            }
        },
        watch:{
            dataVo:function (val) {
                console.log(val)
                this.data = val
                // this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                // this.graph.changeData(this.data);
                // this.graph.fitView();
                if (JSON.stringify(val) != '{}'){
                    this.initEchart()
                }
            }

        },
        mounted() {
            if (typeof window !== 'undefined')
                window.onresize = () => {
                    if (!this.graph || this.graph.get('destroyed')) return;
                    if (!container || !container.scrollWidth || !container.scrollHeight) return;
                    this.graph.changeSize(container.scrollWidth, container.scrollHeight - 30);
                };

        },
        methods: {
             deliverWord  (str, maxWidth, fontSize){
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    console.log(currentWidth,maxWidth)
                    if (currentWidth > maxWidth) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth) {
                        res = `${str.substr(0, i)}\n${str.substr(i)}`;
                    }
                });
                console.log(res)
                return res;
            },
             fittingString  (str, maxWidth, fontSize) {
                const ellipsis = '...';
                const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    if (currentWidth > maxWidth - ellipsisLength) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth - ellipsisLength) {
                        res = `${str.substr(0, i)}${ellipsis}`;
                    }
                });
                return res;
            },
            initEchart() {
                var that = this
                G6.registerNode('card-noded', {
                    getAnchorPoints() {
                        return [
                            [1,0.5], // 左侧中间
                            [0,0.5]
                        ];
                    },
                    draw: function drawShape(cfg, group) {
                        const r = 2;
                        const color = '#CCCCCC';
                        const w = cfg.size[0];
                        const h = cfg.size[1];
                        var lw = 0
                        const shape = group.addShape('rect', {
                            attrs: {
                                x: -w / 2,
                                y: -h / 2,
                                width:cfg.children ? 380 : 500, //200,
                                height:cfg.children ?  h :260, // 60
                                stroke:cfg.children ? '#457DFE' : color,
                                textAlign:'right',
                                radius: 5,
                                fill: cfg.children ? '#457DFE' :'rgba(255,255,255,.3)',
                            },
                            name: 'main-box',
                            draggable: false,
                        });

                        // title text
                        !cfg.children && group.addShape('rect', {
                            attrs: {
                                x: -w/2,
                                y: -h/2,
                                r:[5,5,0,0  ],
                                width: 500, //200,
                                height: 80, // 60
                                fill: 'rgba(69, 125, 254, .4)',
                                stroke:'rgba(69, 125, 254, .4)',

                            },
                            name: 'target',
                        });
                        group.addShape('text', {
                            attrs: {
                                x: -w/2 + 15,
                                y: !cfg.children ?cfg.name.length > 18 ? 50 : 0 : 30,
                                fontSize:28,
                                lineHeight: 32,
                                text:!cfg.children ? cfg.name.length > 17 ? cfg.name.slice(0, 17) + '\n'+ '\n' + cfg.name.slice( -(cfg.name.length - 17)) : cfg.name  : cfg.name.length > 12 ? cfg.name.slice(0, 12) + '\n' + cfg.name.slice( -(cfg.name.length - 12)) : cfg.name ,
                                fill:cfg.children ? '#fff' : '#666',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 -115,
                                y:60,
                                fontSize:16,
                                lineHeight: 24,
                                fontWeight:500,
                                text: `出资比例${cfg.percent}`,
                                fill: '#457DFE',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +15,
                                y:60,
                                fontSize:28,
                                lineHeight: 24,
                                text: `注册资本: ${cfg.regCapital}`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        !cfg.children &&  group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +10,
                                y:100,
                                fontSize:28,
                                lineHeight: 24,
                                text: `法定代表人: ${cfg.legalPersonName}`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +10,
                                y:140,
                                fontSize:28,
                                lineHeight: 24,
                                text: `投资金额: ${cfg.amount}${cfg.amountSuffix}`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        !cfg.children && group.addShape('text', {
                            attrs: {
                                textBaseline: 'center',
                                x: -w/2 +10,
                                y:180,
                                fontSize:28,
                                lineHeight: 24,
                                text: `企业状态: ${cfg.regStatus}`,
                                fill: '#666666',
                            },
                            name: 'target',
                        });
                        return shape;
                    },
                    setState(name, value, item) {
                        console.log(name, value, item);
                        if (name === 'collapsed') {
                            const marker = item.get('group').find((ele) => ele.get('name') === 'collapse-icon');
                            const icon = value ? G6.Marker.expand : G6.Marker.collapse;
                            marker.attr('symbol', icon);
                        }
                    },
                });

                const container = document.getElementById('dwtz');
                const width = container.scrollWidth;
                const height = container.scrollHeight || 800;
                this.graph = new G6.TreeGraph({
                    container: 'dwtz',
                    renderer:'svg',
                    width,
                    height,
                    modes: {
                        default: ['drag-canvas'],
                    },
                    defaultNode: {
                        type: 'card-noded',
                        size: [200, 110],
                    },
                    defaultEdge: {
                        type: 'polyline',
                        style: {
                            radius: 5,
                            offset:150,
                            endArrow: true,
                            lineWidth: 1,
                            stroke: '#ccc',
                        },
                    },
                    layout: {
                        type: 'dendrogram',
                        direction: 'LR',
                        nodeSep:320,
                        rankSep:700,
                    },
                });
                this.graph.on('node:click', (e) => {
                    this.graph.setItemState(e.item, 'dwtz', true);
                });
                this.graph.on('edge:click', (e) => {
                    this.graph.setItemState(e.item, 'dwtz', true);
                });

                this.graph.on('canvas:click', (e) => {
                    this.graph.findAllByState('node', 'dwtz').forEach((node) => {
                        this.graph.setItemState(node, 'dwtz', false);
                    });
                    this.graph.findAllByState('edge', 'dwtz').forEach((edge) => {
                        this.graph.setItemState(edge, 'dwtz', false);
                    });
                });
                this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                this.graph.render(); // 渲染图
                this.graph.fitView();
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 900px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        padding: 5px 10px;
        background: #42b983;
    }
</style>
