<template>
    <div class="main-box">
        <div class="con-box">
            <div class="title">
                <p>企业关联关系图-股权结构图</p>
            </div>
            <div class="content-box">
                <gqjg :data-vo="gq"></gqjg>
            </div>
        </div>
        <div class="con-box">
            <div class="title">
                <p>企业关联关系图-股权穿透图</p>
            </div>
            <div class="content-box">
                <gqct :data-vo="gqct"/>
            </div>
        </div>
        <div class="con-box" >
            <div class="title">
                <p>企业关联关系图-企业对外投资信息</p>
            </div>
            <div class="content-box" style="height: 800px !important">
                <dwtz v-if="flag" :data-vo="dwtz"/>
                <img  v-else style="margin: 0 auto;display: block;padding-top: 250px" src="../assets/images/empty.jpg" alt="">
            </div>
        </div>
        <div class="con-box" >
            <div class="title">
                <p>企业关联关系图-法定代表人对外投资信息</p>
            </div>
            <div class="content-box" style="height: 800px !important">
                <fdtz v-if="fdFlag" :data-vo="fdtz"/>
                <img  v-else style="margin: 0 auto;display: block;padding-top: 250px" src="../assets/images/empty.jpg" alt="">
            </div>
        </div>
        <div class="con-box" >
            <div class="title">
                <p>企业关联关系图-企业关联信息</p>
            </div>
            <div class="content-box" style="height: 800px !important">
                <qygl  :data-vo="qygl"/>
            </div>
        </div>
        <div class="con-box" >
            <div class="title">
                <p>企业关联关系图-上下游信息</p>
            </div>
            <div class="content-box" >
                <sxy :data-vo="sxyData"/>
            </div>
        </div>
    </div>
</template>

<script>
    import gqjg from "./components/company/Gqjg";
    import dwtz from "./components/company/Dwtz";
    import gqct from "./components/company/Gqct";
    import fdtz from "./components/company/Fdtz";
    import sxy from "./components/company/Sxy";
    import qygl from "./components/company/Qygl";
    import {upDownStream,interface453,interface455,interface823,interface1033,interface783} from '@/api/xmfx/glt'
    import {defineComponent,ref} from 'vue'
    import { mapGetters } from 'vuex'
    export default defineComponent({
        computed: {
            ...mapGetters(['orgId', 'isAuth', 'loginType', 'orgName'])
        },
        components:{gqjg,gqct,dwtz,fdtz,sxy,qygl},
        setup() {
            // 表格A企业资源
            const columns = [
                {
                    title: '序号',
                    dataIndex: 'key',
                    key: 'key',
                    align:'center',
                    width: 60
                },
                {
                    title: '报告名称',
                    dataIndex: 'reportName',
                    key: 'reportName',
                    width: '25%',
                    align:'center'
                },
                {
                    title: '季度',
                    dataIndex: 'jidu',
                    key: 'jidu',
                    width: '15%',
                    align:'center'
                },
                {
                    title: '生成时间',
                    dataIndex: 'generatedTime',
                    key: 'generatedTime',
                    width: '15%',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    align:'center',
                    width:'35%',
                    slots: {
                        customRender: 'operation'
                    }
                }
            ]
            return {
                columns
            }
        },
        data () {
            return {
                fdName:'',
                flag: true,
                fdFlag: true,
                dwtz:{},
                fdtz:{},
                gqct:{},
                qygl:{},
                sxyData:{},
                ct:[],
                gq:{},
                page: 1,
                rList: [],
                loading: false,
                pagination: {}
            }
        },
        methods: {
            getGqct(){
                interface455({KEYWORDS:'小米科技有限责任公司'}).then(res=>{
                    if(res.result == 'success'){
                        var nodes = []
                        var edges = []
                        var middle = {
                            isMiddle: true,
                            tagList:res.data_up.result.structure.tagList,
                            name:res.data_up.result.structure.name,
                            parentName:res.data_up.result.structure.name,
                            id:res.data_up.result.structure.id,
                        }

                        if (res.data_down.result !== null){
                            var list = res.data_down.result.items
                            list = list.slice(0,5)
                            list.map((item,index)=>{
                                item.position = 'down'
                                delete item.type
                                item.id = item.id.toString()
                                edges.push({source:middle.id,target:item.id})
                            })
                        }else{
                            list = []
                        }
                        var up = res.data_up.result.structure.children
                        up = up.slice(0,5)
                        up.map((item,index)=>{
                            delete item.type
                            item.position = 'up'
                            edges.push({source:item.id,target:middle.id})
                        })
                        up.push(middle)
                        nodes = up.concat(list)
                        this.gqct = {nodes:nodes,edges:edges}

                    }
                })
            },
            getGqjg(){
                interface453({KEYWORDS:'小米科技有限责任公司'}).then(res=>{
                        if(res.result == 'success'){
                            res.data.result.structure.company = res.data.result.structure.name
                            res.data.result.structure.children.map((item,index)=>{
                                if (item.actualHolding){
                                    this.fdName = item.name
                                }
                                delete item.type
                                if (item.children.length == 0){
                                    delete item.children
                                }
                            })
                            delete res.data.result.structure.type
                            this.gq = res.data.result.structure
                        }
                })
            },
            getDwtz(){
                interface823({KEYWORDS:'哈尔滨往事商贸有限公司'}).then(res=>{
                    if(res.result === 'success'){
                        if (res.data.result !== null){
                            var list = res.data.result.items.slice(0,5)
                            list.map((item,index)=>{
                                item.id = item.id.toString()
                                delete item.type
                            })
                            this.flag = true
                            this.dwtz = {id:'0',name:'小米科技有限责任公司',children:list}
                        }else{
                            this.flag = false
                        }

                    }

                })
            },
            getUpDownStream(){
                upDownStream({ENTERPRISE_ID:'464aa1b28e1c4d89aacf3e4c426b950d'}).then(res=>{
                    if (res.result === 'success'){
                        var a = []
                        var b = []
                        res.varList.map((item,index)=>{
                          if (item.flag == 'up'){
                              if (a.length < 5){
                                  a.push(item)
                              }
                          }else{
                              if (b.length < 5){
                                  b.push(item)
                              }
                          }
                        })
                        this.sxyData = {id:res.ENTERPRISE_ID,company:res.ENTERPRISE_NAME,children:a.concat(b)}
                    }
                })
            },
            getFdtz(){
                interface1033({KEYWORDS:'哈尔滨往事商贸有限公司'}).then(res=>{
                    console.log(res,'1033')
                    if(res.result === 'success'){
                        if (res.data != null){
                            var list = res.data.result.items.slice(0,5)
                            list.map((item,index)=>{
                                item.id = item.id.toString()
                                delete item.type
                            })
                            this.fdFlag = true
                            this.fdtz = {id:'0',name:this.fdName+'\n'+'对外投资',children:list}
                        }else{
                            this.fdFlag = false
                            this.dwtz = {}
                        }

                    }

                })
            },
            getQygl(){
                interface783({KEYWORDS:'哈尔滨往事商贸有限公司'}).then(res=>{
                    console.log(res,'企业图谱')
                    if(res.result == 'success'){
                        var nodes = res.data.result.nodes
                        var edges = res.data.result.relationships
                        console.log(nodes,edges)
                        edges.map((item,index)=>{
                            item.source = item.startNode
                            item.target = item.endNode
                        })
                        this.qygl = {nodes:nodes,edges:edges}
                    }
                })
            },
        },
        mounted() {
            this.getUpDownStream()
            this.getGqjg()
            this.getGqct()
            this.getDwtz()
            this.getFdtz()
            this.getQygl()
        },

    })
</script>
<style lang="less" scoped>
    .content-box ::v-deep(.ant-table-thead){
        background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
    }
    .content-box ::v-deep(.ant-table-thead th){
        background-color: transparent;
        color: @color-ff;
    }

</style>
<style lang="less" scoped>
    .main-box{
        width: @main-width-base;
        margin:20px auto;
        .con-box{
            padding: 15px;
            background-color: @color-ff;
            box-shadow: 0px 0px 10px #929292;
            border-radius: 5px;
            margin: 20px 0;
            position: relative;
            .title{
                font-size: @font-lg;
                padding: 0px 10px;
                padding-bottom: 15px;
                border-bottom: @border-base;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    margin-bottom: 0;
                }
                .title-right{
                    display: flex;
                    justify-content: space-between;
                }
                .tianbao {
                    border: 1px solid #457dfe;
                    border-radius: 5px;
                    padding: 2px 15px;
                    color: #457dfe;
                    cursor: pointer;
                }
            }
            .content-box{
                height: 500px;
                margin-top: 20px;
                font-size: 16px;
            }
        }
        .con-box::before{
            content: '';
            position: absolute;
            left: -3px;
            top: 10px;
            width: 6px;
            height: 40px;
            background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
            background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
            background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
            background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
        }
    }
</style>
