<template>
    <div class="echartLayout">
        <div id="qygl"></div>
    </div>
</template>

<script>
    import G6 from '@antv/g6';
    export default {
        name: "qygl",
        props:{
            dataVo:{
                type:Object,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                graph: {},
                data: {
                    nodes: [
                        {
                            "id": "2190491527-c300741388",
                            "labels": [
                                "Human"
                            ],
                            "properties": {
                                "name": "赵凤舞",
                                "ntype": "s"
                            }
                        },
                        {
                            "id": "119775922",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/6d8d85dbbd264519cb1c9d1047dae448.png@!t07",
                                "aias": "娇海鑫峰",
                                "name": "北京娇海鑫峰商贸有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "1996126007-c3071960686",
                            "labels": [
                                "Human"
                            ],
                            "properties": {
                                "logo": "https://imgh.tianyancha.com/logo/human/77daa1c16316641cdf007c3ff66a69c3_edited_d200x200.png@!t07",
                                "name": "杜飞鹤",
                                "ntype": "s"
                            }
                        },
                        {
                            "id": "1812579986-c3071960686",
                            "labels": [
                                "Human"
                            ],
                            "properties": {
                                "name": "刘晓庆",
                                "ntype": "s"
                            }
                        },
                        {
                            "id": "3071960686",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/76e69878be8ebddf6c9ba8432fb2f893.png@!t07",
                                "aias": "畅购普惠",
                                "name": "畅购普惠（北京）健康管理有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "2856732790",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/321cfa0d0a477baba5cc700df9e8d34d.png@!t07",
                                "aias": "大健康",
                                "name": "深圳市大健康科技发展有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "2349982088",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/85604d5f2187b82f427a8ef9bdff5305.png@!t07",
                                "aias": "畅购",
                                "name": "哈尔滨畅购生物科技有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "2193753137-c186984978",
                            "labels": [
                                "Human"
                            ],
                            "properties": {
                                "name": "赵永贺",
                                "ntype": "s"
                            }
                        },
                        {
                            "id": "186984978",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/e20efb43ef820c60dc98c7bf772dcd52.png@!t07",
                                "aias": "钱宝",
                                "name": "黑龙江钱宝电子科技有限公司长春分公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "300741388",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/bfb887a9182a153c9ca819ccb98379b8.png@!t07",
                                "aias": "子衿教育",
                                "name": "哈尔滨子衿教育信息咨询有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "3091321573",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/00403ae1196761c867ca6c1b32d62272.png@!t07",
                                "aias": "捷步兄弟",
                                "name": "捷步兄弟（北京）科技开发有限公司",
                                "ntype": "f"
                            }
                        },
                        {
                            "id": "1164510967",
                            "labels": [
                                "Company"
                            ],
                            "properties": {
                                "logo": "https://img.tianyancha.com/logo/lll/00e8def0a03fc2ea1ab99074fd66f9ce.png@!t07",
                                "name": "哈尔滨市松北区贯联通讯服务部",
                                "ntype": "f"
                            }
                        }
                    ],
                    edges:[
                        {
                            "id": "17764420",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0.7",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "2349982088"
                        },
                        {
                            "id": "1213193601",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0.5",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "119775922"
                        },
                        {
                            "id": "1051690559",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "3091321573"
                        },
                        {
                            "id": "1061616593",
                            "source": "1996126007-c3071960686",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "经理,执行董事"
                                ]
                            },
                            "type": "SERVE",
                            "target": "3071960686",
                            value:'12'
                        },
                        {
                            "id": "190671236",
                            "source": "2856732790",
                            "properties": {
                                "percent": "0.05",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_C",
                            "target": "3071960686"
                        },
                        {
                            "id": "1079137609",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "执行董事兼总经理"
                                ]
                            },
                            "type": "SERVE",
                            "target": "300741388"
                        },
                        {
                            "id": "1218259590",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0.3",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "3091321573"
                        },
                        {
                            "id": "337158712",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "1164510967"
                        },
                        {
                            "id": "1058779642",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "监事"
                                ]
                            },
                            "type": "SERVE",
                            "target": "119775922"
                        },
                        {
                            "id": "1056241110",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "2349982088"
                        },
                        {
                            "id": "211368442",
                            "source": "1996126007-c3071960686",
                            value:11,
                            "properties": {
                                "percent": "0.2",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "3071960686"
                        },
                        {
                            "id": "1006229663",
                            "source": "2193753137-c186984978",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "监事"
                                ]
                            },
                            "type": "SERVE",
                            "target": "2349982088"
                        },
                        {
                            "id": "1213192485",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "经理,执行董事"
                                ]
                            },
                            "type": "SERVE",
                            "target": "3091321573"
                        },
                        {
                            "id": "145928093",
                            "source": "2193753137-c186984978",
                            "properties": {
                                "percent": "0.3",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "2349982088"
                        },
                        {
                            "id": "701280611",
                            "source": "2193753137-c186984978",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "186984978"
                        },
                        {
                            "id": "323975456",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "监事"
                                ]
                            },
                            "type": "SERVE",
                            "target": "3071960686"
                        },
                        {
                            "id": "223598625",
                            "source": "1996126007-c3071960686",
                            value:22,
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "3071960686"
                        },
                        {
                            "id": "526266344",
                            "source": "2349982088",
                            "properties": {
                                "percent": "0.7",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_C",
                            "target": "3071960686"
                        },
                        {
                            "id": "495763636",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0",
                                "labels": [
                                    "法定代表人"
                                ]
                            },
                            "type": "OWN",
                            "target": "300741388"
                        },
                        {
                            "id": "1173797386",
                            "source": "2190491527-c300741388",
                            "properties": {
                                "percent": "0.51",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "300741388"
                        },
                        {
                            "id": "251210502",
                            "source": "1812579986-c3071960686",
                            "properties": {
                                "percent": "0.05",
                                "labels": [
                                    "参股"
                                ]
                            },
                            "type": "INVEST_H",
                            "target": "3071960686"
                        }
                    ]
                },
            }
        },
        watch:{
            dataVo:function (val) {
                console.log(val,'企业关联')
                this.data = val
                // this.graph.data(this.data); // 读取 Step 2 中的数据源到图上
                // this.graph.changeData(this.data);
                // this.graph.fitView();
                G6.Util.processParallelEdges(this.data.edges,4);
                this.initEchart()
            }

        },
        mounted() {
            // G6.Util.processParallelEdges(this.data.edges,4);
            // this.initEchart()
        },
        methods: {
             formatString( result , num) {
                 var str = result
                 var arr = ''
                 var n =parseInt(result.length / num)
                 console.log(n,'woshin')
                 switch (n) {
                     case 0:
                         arr =  str
                         break;
                    case 1:
                        arr =  str.slice(0,5)+'\n'+    str.slice(5,str.length)
                         break;
                     case 2:
                         arr =  str.slice(0,5)+'\n'+    str.slice(5,10)+'\n'+ str.slice(10,str.length)
                         break;
                     case 3:
                         arr =  str.slice(0,5)+'\n'+    str.slice(5,10)+'\n'+ str.slice(10,15)+'\n'+str.slice(15,str.length)
                         break;
                     case 4:
                         arr =  str.slice(0,5)+'\n'+    str.slice(5,10)+'\n'+ str.slice(10,15)+'\n'+str.slice(15,20)+'...'
                         break;
                 }
                 // if (n>0){
                 //     console.log(121)
                 //     for(var i = 0;i<n; i++){
                 //         var start = n*m
                 //         console.log(i,'我是i')
                 //         arr[i] =  str.slice(n, num*n)
                 //     }
                 // }else{
                 //     arr = str
                 // }
                 console.log(arr)
                 return arr
                 // var array = new Array();
        // var str = result;
        // var len = str.length;
        // for(var i=0;i<(str.length/num);i++)
        // {
        //    var str1 = str.substring(str.length-(i+1)*num,str.length-i*num);
        //     array.push(str1);
        // }
        // return array;
    },

            deliverWord  (str, maxWidth){
                let currentWidth = 0;
                let res = str;
                if (str.length)
                // const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                // str.split('').forEach((letter, i) => {
                //     console.log(currentWidth,maxWidth)
                //     if (currentWidth > maxWidth) return;
                //     if (pattern.test(letter)) {
                //         // Chinese charactors
                //         currentWidth += fontSize;
                //     } else {
                //         // get the width of single letter according to the fontSize
                //         currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                //     }
                //     console.log(currentWidth,maxWidth)
                //     if (currentWidth > maxWidth) {
                //         res = `${str.substr(0, i)}\n${str.substr(i)}`;
                //     }
                // });
                console.log(res)
                return res;
            },
            fittingString  (str, maxWidth, fontSize) {
                const ellipsis = '...';
                const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
                let currentWidth = 0;
                let res = str;
                const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese charactors and letters
                str.split('').forEach((letter, i) => {
                    if (currentWidth > maxWidth - ellipsisLength) return;
                    if (pattern.test(letter)) {
                        // Chinese charactors
                        currentWidth += fontSize;
                    } else {
                        // get the width of single letter according to the fontSize
                        currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                    }
                    if (currentWidth > maxWidth - ellipsisLength) {
                        res = `${str.substr(0, i)}${ellipsis}`;
                    }
                });
                return res;
            },
            initEchart() {
                G6.registerNode(
                    'sql',
                    {
                        drawShape(cfg, group) {
                            const rect = group.addShape('circle', {
                                attrs: {
                                    r: 10,
                                    fill:cfg.labels[0] === 'Human' ? '#e27167' :cfg.labels[0] === 'Company' ? '#599ff3': '#5ab7bd',
                                },
                                name: 'rect-shape',
                            });
                            cfg.properties.logo &&  cfg.properties.ntype ==='s' && group.addShape('image', {
                                attrs: {
                                    width: 20,
                                    height: 20,
                                    clipCfg: {
                                        show: true,
                                        type: 'circle',
                                        r: 20
                                    },
                                    x: -10,
                                    y: -10,
                                    img: cfg.properties.logo,
                                },
                                name: 'image-shape',
                            });
                            var str = cfg.properties.name.slice(0,5) + '\n'+cfg.properties.name.slice(5,10)
                            group.addShape('text', {
                                   attrs: {
                                    y:cfg.properties.ntype === 's' ? cfg.properties.logo ? 7 :2   :7,
                                    text: that.formatString(cfg.properties.name,5),
                                    fill: '#fff',
                                    fontSize: 3,
                                    fontWeight: 'bold',
                                    textAlign:'center'
                                },
                                name: 'text-shape',
                            });
                            return rect;
                        },
                    },
                    'single-node',
                );

                const container = document.getElementById('qygl');
                const width = container.scrollWidth;
                const height = container.scrollHeight || 800;
                 this.graph = new G6.Graph({
                    container: 'qygl',
                    renderer:'svg',
                    width,
                    height,
                    fitView: true,
                    modes: {
                        default: ['drag-canvas', 'drag-node'],
                    },
                    layout: {
                        type: 'radial',
                        unitRadius: 70,
                        preventOverlap: true,
                        maxPreventOverlapIteration: 40,
                    },
                    defaultNode: {
                        size: [80, 80],
                        type: 'sql',
                    },
                    animate: true,
                    defaultEdge: {
                        type: 'polyline',
                        multiEdgeType: 'polyline',
                        style: {
                            lineWidth: 0.4,
                            stroke:'#ccc' ,
                        },
                    },
                    nodeStateStyles: {
                        selected: {
                            stroke: '#d9d9d9',
                            fill: '#5394ef',
                        },
                    },
                });
                var that = this
                // this.data.nodes.forEach(function (node) {
                //     node.properties.name = that.fittingString( node.properties.name, 20, 3);
                // });
                this.graph.edge((edge) => {
                    console.log(edge)
                    return {
                        label:edge.properties.labels[0],
                        labelCfg:{
                            autoRotate:true,
                       style:{
                           fill:'#000',
                           fontSize:3,
                           endArrow:true
                       }
                        },
                        style: {

                            lineWidth: .5,
                            stroke:edge.properties.labels[0] === '参股'?'#e27167': edge.properties.labels[0] === '法定代表人'?'#5ab7db' :'#599ff3',
                        },
                        anchorPoints: [
                            [0, 0.5],
                            [1, 0.5],
                        ],
                    };
                });
                // this.data.edges.forEach(function (edge) {
                //     that.data.edges.push({
                //         source:edge.source,
                //         target: edge.target,
                //     });
                    // var start = edge.source
                    // var end = edge.target
                    // if (start === edge.source && end === edge.target){
                    //     console.log(121212121)
                    // }
                    // graph.updateItem(edge, {
                    //     curveOffset: edge.startPoint,
                    //     curvePosition: edge[i].curvePosition,
                    // });
                    // edge.label = this.fittingString(edge.parentName, 32, 32);
                // });
                this.graph.data(this.data);
                this.graph.render();
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 900px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        padding: 5px 10px;
        background: #42b983;
    }
</style>
